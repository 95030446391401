import React from "react";
import Navbar from "../components/Navbar";
import Slider from "../components/Slider";
import ImageComponent from "../components/ImageComponent";
import CategoryGrid from "../components/CategoryGrid";
import { Dialog } from "primereact/dialog";
import callsvc from "../utils/Services";
import Global from "../utils/Global";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast as Growl } from "primereact/toast";
import userProfile from "../utils/Userprofile";
import { InputMask } from "primereact/inputmask";
import { ProgressSpinner } from "primereact/progressspinner";
import { Sidebar } from "primereact/sidebar";
import CartStrip from "../components/CartStrip";
import Sidemenu from "../components/SideMenu";
import { appTheme } from "../utils/Constants";
import { APP_ID } from "../utils/Constants";
import Cart from "./Cart";
import { Card } from "primereact/card";
import delimg from "../assets/img/del.png";
import cart0 from "../assets/img/cart0.png";
import textlogo from "../assets/img/svr_text_logo_modified.png";

import { InputTextarea } from "primereact/inputtextarea";
import ProductCard from "../components/ProductCard";
import ProductEdit from "../components/ProductEdit";
import { RadioButton } from "primereact/radiobutton";
import CartPage from "../components/CartPage";
import { askForPermissionToReceiveNotifications } from "../push-notification";
export default class Home extends React.Component {
  constructor(props) {
    super(props);
    const custobj = userProfile.getCustObj();
    const selLoc = custobj && custobj.location ? custobj.location : {};
    this.state = {
      productList: [],
      itemlist: [],
      loginDialog: false,
      loading: false,
      phone: "",
      username: "",
      otp: "",
      loginScreen: true,
      otpScreen: false,
      showSpinner: false,
      counter: 15,
      catlist: [],
      cartlist: {
        orditlist: [],
      },
      cartCount: 0,
      notifyCount: 0,
      showcartDialog: false,
      shownotifyDialog: false,
      showsearchDialog: false,
      loclist: [],
      selLoc: selLoc,
      showLocDialog: false,
      cookInst: "",
      couponDisc: 0,
      latitude: "",
      longitude: "",
      community: "",
      altphone: "",
      flat: "",
      searchTag: "",
      showOrderItDialog: false,
      installPrompt: false,
      showProductEdit: false,
      selProduct: null,
      orderit: null,
      delopt: "Delivery",
      promoApplied: false,
      promocode: "",
      promoRes: {},
      openStatus: true,
      closeStatus: false,
      closeReason: "",
    };
    Global.setHomeRef(this);
  }

  componentDidUpdate(prevProps) {}

  chooseLocation = (it) => {
    let custobj = userProfile.getCustObj();
    if (custobj) {
      custobj.location = it;
      custobj.orgid = it.orgid;
    } else {
      custobj = { location: it, orgid: it.orgid };
    }
    userProfile.setCustObj(custobj);

    for (let i = 0; i < this.state.loclist.length; i++) {
      if (this.state.loclist[i].locid == it.locid) {
        this.state.loclist[i].selected = true;
      } else {
        this.state.loclist[i].selected = false;
      }
    }
    this.setState({ selLoc: it, showLocDialog: false });
    this.getHome();
  };

  componentDidMount(props) {
    console.log(this.props.navProps);

    //console.log(this.state.selLoc)
    // this.getHome();
    let locExist = false;
    callsvc({ appid: APP_ID }, "getloc", true)
      .then((res) => {
        if (this.state.selLoc.locid) {
          for (let i = 0; i < res.locations.length; i++) {
            if (res.locations[i].locid == this.state.selLoc.locid) {
              locExist = true;
              res.locations[i].selected = true;
            } else {
              res.locations[i].selected = false;
            }
          }
          this.setState({ loclist: res.locations });
        } else {
          this.setState({ loclist: res.locations, showLocDialog: true });
          console.log("location not selected");
        }

        if (locExist) {
          this.getHome();
        }
      })
      .catch((err) => {})
      .finally(() => {});

    let deferredPrompt;

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      console.log(e);
      // Update UI notify the user they can install the PWA
      //showInstallPromotion();
      this.setState({ installPrompt: e });
    });
    //deferredPrompt.prompt();
  }

  getCart = (custObj, prdupdate = false) => {
    callsvc(
      { orgid: Global.defaultOrg, custid: custObj.custid },
      "getnewcart",
      false
    )
      .then((res) => {
        if (res.code == "999" || res.code == "9991") {
          //console.log('cart Arrived');

          for (let i = 0; i < this.state.catlist.length; i++) {
            for (let j = 0; j < this.state.catlist[i].products.length; j++) {
              this.state.catlist[i].products[j].prdquan = 0;
              for (let k = 0; k < res.orditlist.length; k++) {
                res.orditlist[k]["itemid"] = res.orditlist[k]["prditid"];
                if (
                  res.orditlist[k]["prdid"] ==
                  this.state.catlist[i].products[j].id
                ) {
                  this.state.catlist[i].products[j].prdquan =
                    res.orditlist[k]["itemcount"];
                }
              }
            }
          }
          // if(prdupdate){
          //     this.updateItems(res.orditlist[i].prdid,res.orditlist[i].prditid,res.orditlist[i].prdquan)
          // }

          // console.log(res)
          this.setState({
            cartlist: res,
            cartCount: res.cartcount,
            catlist: this.state.catlist,
            promocode: "",
            promoRes: {},
            promoApplied: false,
          });
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  updateProdQuan = (prdid, prdquan) => {
    //console.log(this.state.catlist)

    for (let i = 0; i < this.state.catlist.length; i++) {
      for (let j = 0; j < this.state.catlist[i].products.length; j++) {
        if (prdid == this.state.catlist[i].products[j].id) {
          this.state.catlist[i].products[j].prdquan = prdquan;
          break;
        }
      }
    }
    this.setState({ catlist: this.state.catlist });
  };

  updateItems = (prdid, prditid, prdquan, addonid = null, type = null) => {
    let catlist = this.state.catlist;
    for (let i = 0; i < catlist.length; i++) {
      for (let j = 0; j < catlist[i].products.length; j++) {
        if (prdid == catlist[i].products[j].id) {
          for (let k = 0; k < catlist[i].products[j].proditems.length; k++) {
            if (prditid == catlist[i].products[j].proditems[k].itemid) {
              catlist[i].products[j].proditems[k].selected = true;
              catlist[i].products[j].proditems[k].prdquan = parseInt(prdquan);

              if (addonid) {
                for (
                  let l = 0;
                  l < catlist[i].products[j].proditems[k].addons.length;
                  l++
                ) {
                  for (
                    let m = 0;
                    m <
                    catlist[i].products[j].proditems[k].addons[l].data.length;
                    m++
                  ) {
                    if (type == "radio") {
                      if (
                        catlist[i].products[j].proditems[k].addons[l].data[m]
                          .addonid == addonid
                      ) {
                        catlist[i].products[j].proditems[k].addons[l].data[
                          m
                        ].selected = catlist[i].products[j].proditems[k].addons[
                          l
                        ].data[m].selected
                          ? false
                          : true;
                      } else {
                        catlist[i].products[j].proditems[k].addons[l].data[
                          m
                        ].selected = false;
                      }
                    } else {
                      if (
                        catlist[i].products[j].proditems[k].addons[l].data[m]
                          .addonid == addonid
                      ) {
                        catlist[i].products[j].proditems[k].addons[l].data[
                          m
                        ].selected = catlist[i].products[j].proditems[k].addons[
                          l
                        ].data[m].selected
                          ? false
                          : true;
                      }
                    }
                    //if(catlist[i].products[j].proditems[k].addons[l].data[m].selected)
                    //catlist[i].products[j].proditems[k].seltotal = parseInt(catlist[i].products[j].proditems[k].seltotal) + parseInt(catlist[i].products[j].proditems[k].addons[l].data[m].price)
                  }
                }
              }
            } else {
              catlist[i].products[j].proditems[k].selected = false;
            }
          }
        } else {
          continue;
        }
      }
    }

    this.setState({ catlist: catlist }, () => {
      // console.log(this.state.catlist)
    });
  };

  getHome = () => {
    callsvc({ orgid: Global.defaultOrg }, "getfoodhome", false)
      .then((res) => {
        let productList = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].type == "CATEGORY_GRID") {
            for (let j = 0; j < res.data[i].items.length; j++) {
              res.data[i].items[j]["selected"] = j == 0 ? true : false;

              for (let k = 0; k < res.data[i].items[j].products.length; k++) {
                res.data[i].items[j].products[k].prdquan = 0;

                for (
                  let l = 0;
                  l < res.data[i].items[j].products[k].proditems.length;
                  l++
                ) {
                  res.data[i].items[j].products[k].proditems[l].prdquan = 0;
                  res.data[i].items[j].products[k].proditems[l].selected =
                    l == 0 ? true : false;
                  res.data[i].items[j].products[k].proditems[l].seltotal =
                    res.data[i].items[j].products[k].proditems[l].price;
                  for (
                    let m = 0;
                    m <
                    res.data[i].items[j].products[k].proditems[l].addons.length;
                    m++
                  ) {
                    res.data[i].items[j].products[k].proditems[l].addons[m].id =
                      i + j + k + l + m;
                  }
                }
                productList.push(res.data[i].items[j].products[k]);
              }
            }

            this.setState({
              catlist: res.data[i].items,
              productList: productList,
            });
            break;
          }
        }

        let custObj = userProfile.getCustObj();
        if (custObj && custObj.custid) {
          this.getCart(custObj, true);
        }

        this.setState({
          itemlist: res.data,
          openStatus: res.orgdetails.status == "open" ? true : false,
          closeStatus: res.orgdetails.status == "open" ? false : true,
          closeReason: res.orgdetails.reason,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  custLogin = (loadOpt = true) => {
    if (loadOpt == false) {
      this.setState({ loading: false, showSpinner: true });
    } else {
      this.setState({ loading: true, showSpinner: true });
    }
    if (this.state.phone.length < 10 || this.state.username.length < 3) {
      this.growl.show({
        severity: "warn",
        summary: "Invalid Data",
        detail: "Please enter valid information!",
        life: 6000,
      });
      this.setState({ loading: false });
      return;
    }
    callsvc(
      {
        orgid: Global.defaultOrg,
        fstname: this.state.username,
        accid: this.state.phone,
      },
      "custlogin",
      false
    )
      .then((res) => {
        if (res.code == "999") {
          //console.log(res.data);
          //userProfile.setCustObj(res.data);
          this.growl.show({
            severity: "info",
            summary: "OTP",
            detail: "OTP has been sent to your mobile, please check!",
            life: 6000,
          });
          this.setState({
            showSpinner: false,
            loginScreen: false,
            otpScreen: true,
            otp: "",
          });
        } else {
          this.growl.show({
            severity: "warn",
            summary: "Error!",
            detail: res.message,
            life: 6000,
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "warn",
          summary: "Error!",
          detail: err.message,
          life: 6000,
        });
      })
      .finally(() => {
        this.setState({ loading: false, showSpinner: false });
      });
  };

  setSelectedTab = (it) => {
    for (let i = 0; i < this.state.catlist.length; i++) {
      if (it.pdlid == this.state.catlist[i].pdlid) {
        this.state.catlist[i]["selected"] = true;
      } else {
        this.state.catlist[i]["selected"] = false;
      }
    }
    this.setState({ catlist: this.state.catlist });
  };

  resendOtp = () => {
    let interval = setInterval(() => {
      if (this.state.counter > 0 && this.state.counter <= 15) {
        this.setState({ counter: this.state.counter - 1 });
      } else {
        this.setState({ counter: 15 });
        clearInterval(this.state.interval);
      }
    }, 1000);
    this.setState({ interval: interval });
    this.custLogin(false);
  };

  hideDialog = () => {
    clearInterval(this.state.interval);
    this.setState({
      loginDialog: false,
      loginScreen: true,
      otpScreen: false,
      counter: 15,
    });
  };

  verifyOtp = () => {
    this.setState({ loading: true, showSpinner: true });
    if (this.state.otp.length < 4) {
      this.growl.show({
        severity: "warn",
        summary: "Invalid Data",
        detail: "Please enter valid otp!",
        life: 6000,
      });
      this.setState({ loading: false });
      return;
    }
    let otp = this.state.otp.replace("-", "");
    callsvc(
      { orgid: Global.defaultOrg, accid: this.state.phone, otp: otp },
      "valotp",
      false
    )
      .then((res) => {
        if (res.code === "999") {
          let custobj = userProfile.getCustObj();
          custobj = { ...custobj, ...res.data };
          userProfile.setCustObj(custobj);
          this.growl.show({
            severity: "success",
            summary: "Success",
            detail: "Success!",
            life: 6000,
          });
          this.setState({
            loginDialog: false,
            loginScreen: true,
            otpScreen: false,
          });
          this.getCart(custobj);
          clearInterval(this.state.interval);
          //console.log(custobj.custid)
          //notification access
          askForPermissionToReceiveNotifications(custobj.custid);
        } else {
          this.growl.show({
            severity: "warn",
            summary: res.message,
            life: 6000,
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "warn",
          summary: "Error",
          detail: err.message,
          life: 6000,
        });
      })
      .finally(() => {
        this.setState({ loading: false, showSpinner: false });
      });
  };

  applyPromo = () => {
    if (this.state.promocode.length < 3) {
      this.growl.show({
        severity: "warn",
        summary: "Invalid!",
        detail: "Please enter valid coupon",
        life: 6000,
      });
    }
    if (!this.state.promoApplied) {
      callsvc(
        {
          orgid: userProfile.getCustObj().orgid,
          custid: userProfile.getCustObj().custid,
          promocode: this.state.promocode,
        },
        "applypromo",
        false
      ).then((res) => {
        if (res.code == "999") {
          this.setState({ promoRes: res, promoApplied: true });
          this.growl.show({
            severity: "success",
            summary: "Success!",
            detail: res.message,
            life: 6000,
          });
          return;
        } else {
          this.growl.show({
            severity: "warn",
            summary: "Invalid!",
            detail: res.message,
            life: 6000,
          });
        }
      });
    } else {
      this.setState({ promoApplied: false, promocode: "", promoRes: {} });
    }
  };

  handleCartDialog = (val) => {
    this.setState({ showcartDialog: val });
    //console.log('cart opened')

    //this.getGeoLocation();
  };

  clearCart = () => {
    callsvc({ ordid: this.state.cartlist.ordid }, "clearcart", true)
      .then((res) => {
        if (res.code == "999") {
          this.setState({ cartlist: { orditlist: [] }, cartCount: 0 });
          this.getCart(userProfile.getCustObj().custid);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  getPrdList = () => {
    if (this.state.showsearchDialog && this.state.searchTag.length > 1) {
      return this.state.productList.filter((obj) => {
        return (
          obj.searchtag
            .toLowerCase()
            .indexOf(this.state.searchTag.toLowerCase()) > -1
        );
      });
    } else {
      return [];
    }
  };

  render() {
    //console.log('home rendered');
    //console.log(this.state.productList)
    const prdList = this.getPrdList();
    //console.log('home render')
    return (
      <>
        <div
          style={{
            height: 55,
            width: "100%",
            display: "flex",
            backgroundColor: appTheme.primaryColor,
            color: appTheme.textColor,
            alignItems: "center",
            position: "sticky",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 999,
          }}
        >
          <Sidemenu navProps={this.props.navProps} />
          <div style={{ marginLeft: 0 }}>
            <img style={{ width: 200, height: 50 }} src={textlogo} />
          </div>
          <div style={{ flex: 1 }}></div>
          <i
            className="pi pi-search"
            style={{ fontSize: "1.5rem", marginRight: 12 }}
            onClick={() => {
              this.setState({ showsearchDialog: true });
            }}
          ></i>
          <div
            style={{ position: "relative", marginRight: 20 }}
            onClick={() => {
              this.handleCartDialog(true);
            }}
          >
            <i
              className="pi pi-shopping-cart"
              style={{ fontSize: "1.6rem" }}
            ></i>
            <div
              style={{
                backgroundColor: appTheme.btnbgcolor,
                width: 20,
                height: 20,
                position: "absolute",
                top: -8,
                right: -6,
                borderRadius: 8,
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {this.state.cartCount}
            </div>
          </div>
          <div
            style={{ position: "relative", marginRight: 20 }}
            onClick={() => {
              this.setState({ shownotifyDialog: true });
            }}
          >
            <i className="pi pi-bell" style={{ fontSize: "1.5rem" }}></i>
            <div
              style={{
                backgroundColor: appTheme.btnbgcolor,
                width: 20,
                height: 20,
                position: "absolute",
                top: -8,
                right: -6,
                borderRadius: 8,
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {this.state.notifyCount}
            </div>
          </div>
        </div>
        {this.state.cartCount > 0 && (
          <CartStrip
            cartCount={this.state.cartCount}
            navProps={this.props.navProps}
            cartOpen={() => {
              this.handleCartDialog(true);
            }}
          />
        )}
        <Growl
          ref={(el) => (this.growl = el)}
          style={{ width: "70vw" }}
          sticky={"true"}
          position="top-right"
        />
        {this.state.showSpinner && (
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}

        {this.state.itemlist.map((it) => {
          switch (it.type) {
            case "SLIDER":
              return <Slider key={it.id} data={it} />;
            case "IMAGE":
              return Global.isMobile ? (
                <img
                  key={it.id}
                  id={it.id}
                  src={it.imgurl}
                  style={{ width: "100%", height: parseInt(it.height) }}
                />
              ) : (
                <img
                  key={it.id}
                  id={it.id}
                  src={it.imgurl}
                  style={{
                    width: "75%",
                    height: parseInt(it.height),
                    marginLeft: "10rem",
                  }}
                />
              );
            case "CATEGORY_GRID":
              return (
                <CategoryGrid
                  key={it.id}
                  data={it}
                  setSelectedTab={this.setSelectedTab}
                />
              );
            default:
              return null;
          }
        })}

        <Dialog
          header="Login"
          visible={this.state.loginDialog}
          style={{ width: "70vw" }}
          onHide={() => this.hideDialog()}
        >
          {this.state.loginScreen && (
            <div
              id="loginflds"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "0.5em",
              }}
            >
              <span className="p-float-label" style={{ margin: 10 }}>
                <InputText
                  id="phone"
                  value={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                  required
                />
                <label htmlFor="phone">Phone</label>
              </span>

              <span className="p-float-label" style={{ margin: 10 }}>
                <InputText
                  id="username"
                  value={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                  required
                />
                <label htmlFor="username">Username</label>
              </span>

              <Button
                label="Login"
                loading={this.state.loading}
                onClick={() => this.custLogin()}
                text
                raised
              />
            </div>
          )}
          {this.state.otpScreen && (
            <div
              id="loginflds"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <span className="p-float-label" style={{ marginBottom: 5 }}>
                <InputMask
                  id="otp"
                  mask="99-99"
                  onChange={(e) => {
                    this.setState({ otp: e.target.value });
                  }}
                ></InputMask>
                <label htmlFor="otp">OTP</label>
              </span>
              <div style={{ marginLeft: "4rem" }}>
                {this.state.counter >= 15 && (
                  <Button
                    style={{ color: "#6366F1" }}
                    onClick={() => {
                      this.resendOtp();
                    }}
                    label="Resend"
                    link
                  />
                )}
                {this.state.counter < 15 && (
                  <div>
                    <span style={{ color: "#6366F1" }}>
                      Resend otp in {this.state.counter} sec
                    </span>
                  </div>
                )}
              </div>

              <Button
                label="Submit"
                loading={this.state.loading}
                onClick={() => this.verifyOtp()}
                text
                raised
              />
            </div>
          )}
        </Dialog>
        <Dialog
          header="Closed!"
          visible={!this.state.openStatus}
          onHide={() => this.setState({ openStatus: true })}
          style={{ width: "70vw" }}
        >
          <div style={{}}>{this.state.closeReason}</div>
        </Dialog>
        <CartPage
          showcartDialog={this.state.showcartDialog}
          hideCartDialog={() => {
            this.setState({ showcartDialog: false });
          }}
          cartlist={this.state.cartlist}
          navProps={this.props.navProps}
        />

        <Dialog
          header="Notifications"
          visible={this.state.shownotifyDialog}
          style={{
            maxHeight: "100%",
            width: "100vw",
            height: "100vh",
            margin: "0px !important",
          }}
          onHide={() => {
            this.setState({ shownotifyDialog: false });
          }}
        >
          <div style={{ textAlign: "center", marginTop: "3rem" }}>
            No New Notifications!
          </div>
        </Dialog>

        <Sidebar
          className="cart-screen"
          style={{
            width: "100%",
            height: "100%",
            padding: "0px !important",
            margin: "0px !important",
          }}
          visible={this.state.showsearchDialog}
          showCloseIcon={false}
          onHide={() => this.setState({ showsearchDialog: false })}
          fullScreen
        >
          <div
            style={{
              height: 55,
              width: "100%",
              padding: "0px !important",
              display: "flex",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 999,
              backgroundColor: appTheme.primaryColor,
              color: appTheme.textColor,
            }}
          >
            <i
              className="pi pi-chevron-left"
              style={{ fontSize: "1.2rem", marginRight: 12, marginLeft: 12 }}
              onClick={() => {
                this.setState({ showsearchDialog: false, searchTag: "" });
              }}
            ></i>
            <span
              className={
                this.state.searchTag.length > 0
                  ? "p-input-icon-right"
                  : "p-input-icon-left"
              }
              style={{ width: "100%", marginRight: 12 }}
            >
              <i
                onClick={() => {
                  this.setState({ searchTag: "" });
                }}
                className={
                  this.state.searchTag.length > 0
                    ? "pi pi-times"
                    : "pi pi-search"
                }
              />
              <InputText
                className="p-inputtext-sm"
                placeholder="Search our delicious menu"
                style={{ width: "100%" }}
                value={this.state.searchTag}
                onChange={(e) => {
                  this.setState({ searchTag: e.target.value });
                }}
              />
            </span>
          </div>
          <div style={{ height: 20 }}></div>
          <div>
            {Global.isMobile ? (
              <div>
                {prdList.map((it) => (
                  <ProductCard key={it.id} data={it} type="list" />
                ))}
              </div>
            ) : (
              <div className="grid" style={{ margin: 12 }}>
                {prdList.map((it) => {
                  return <ProductCard key={it.id} data={it} />;
                })}
              </div>
            )}
          </div>
        </Sidebar>

        <Sidebar
          className="loc-side-bar"
          style={{
            width: "100%",
            height: "100%",
            padding: "0px !important",
            margin: "0px !important",
          }}
          visible={this.state.showLocDialog}
          showCloseIcon={false}
          onHide={() => this.setState({ showLocDialog: false })}
          fullScreen
        >
          <div
            style={{
              height: 55,
              width: "100%",
              padding: "0px !important",
              display: "flex",
              backgroundColor: appTheme.primaryColor,
              color: appTheme.textColor,
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 999,
            }}
          >
            <i
              className="pi pi-times-circle"
              style={{ fontSize: "1rem", marginRight: 12, marginLeft: 12 }}
              onClick={() => {
                this.setState({ showLocDialog: false });
              }}
            ></i>
            <div style={{ fontSize: "1rem", marginLeft: 12 }}>
              Delivery Locations
            </div>
            <div style={{ flex: 1 }}></div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 40,
            }}
          >
            <img src={delimg} style={{ width: 120, height: 120 }} />
          </div>

          <div
            style={{
              margin: 12,
              textAlign: "center",
              color: appTheme.btnbgcolor,
              fontSize: "1.4rem",
              fontWeight: "800",
            }}
          >
            We Currently Deliver To
          </div>
          <div
            style={{
              margin: 12,
              textAlign: "center",
              color: "#333",
              fontSize: ".8rem",
              fontWeight: "500",
            }}
          >
            Please choose location
          </div>
          {this.state.loclist.map((it) => (
            <div
              key={it.locid}
              id={it.locid}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 4,
                borderBottom: "1px solid #CCCDC6",
                backgroundColor: "#f9f9f9",
                padding: 12,
                width: Global.isMobile ? "100%" : "50%",
                margin: "auto",
              }}
              onClick={() => {
                this.chooseLocation(it);
              }}
            >
              <div>{it.loc}</div>
              <div style={{ flex: 1 }}></div>
              <i
                className="pi pi-chevron-circle-right"
                style={{ fontSize: "1rem", marginRight: 12 }}
              ></i>
            </div>
          ))}
        </Sidebar>
      </>
    );
  }
}
