import React from "react";
import { appTheme } from "../utils/Constants";
import userProfile from "../utils/Userprofile";
import callsvc from "../utils/Services";
import pizzicon from "../assets/img/logo512.png";
import { Sidebar } from "primereact/sidebar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast as Growl } from "primereact/toast";

class Orders extends React.Component{

    constructor(props){
        //console.log('order constructor')
        super(props)
        this.state = {
            ordlist : [],
            userObj:userProfile.getCustObj(),
            summaryDialog:false,
            orditems :[],
            selectedOrder:{},
            cancelPopup:false,
            cancelReason:'',
            loading:false
        }
    }

    componentDidMount(){
        //console.log('order mount')
        this.getOrders();
    }

    getOrders=()=>{
        callsvc({custid:this.state.userObj.custid},'getorderlist',false)
        .then((res)=>{
            if(res.code=='999'){
                //console.log(res)
                this.setState({ordlist:res.orderslist})
            }
        })
    }

    orderItems = (item)=>{
        this.setState({selectedOrder:item,summaryDialog:true});
        callsvc({custid:this.state.userObj.custid,ordid:item.ordid},'getorderitemlist',false)
        .then((res)=>{
            if(res.code=='999'){
                //console.log(res)
                this.setState({orditems:res.orditlist})
            }
        })

    }

    cancelFooter=()=>{
        return(
            <div>
            <Button label="No"  onClick={() => this.setState({cancelPopup:false})} className="p-button-text"  autoFocus/>
            <Button label="Cancel"  loading={this.state.loading}  onClick={() => this.cancelOrder()} />
            </div>

        )
    }

    cancelOrder = ()=>{
        if(this.state.cancelReason.length < 3){
            this.growl.show({severity:'warn',detail:'Please enter valid reason to cancel..',summary:'Invalid!',time:6000})
            return
        }
        this.setState({loading:true})
        callsvc({custid:this.state.userObj.custid,ordid:this.state.selectedOrder.ordid,reason:this.state.cancelReason},'cancelorder',false)
        .then((res)=>{
            if(res.code=='999'){

                for(let i=0;i<this.state.ordlist.length;i++){
                    if(this.state.selectedOrder.ordid == this.state.ordlist[i].ordid)
                    this.state.ordlist[i].ordstatus = 'CANCELLED'
                }

                this.growl.show({severity:'success',detail:'Your order cancelled successfully!',summary:'Success',time:6000})
                this.setState({loading:false,cancelPopup:false,ordlist:this.state.ordlist})
            }else{
                this.growl.show({severity:'success',detail:'Something went wrong please contact us!',summary:'Something went wrong!',time:6000})
            }
        }).catch(err=>{
            this.growl.show({severity:'success',detail:err.message,summary:'Error',time:6000})
        }).finally(()=>{})
    }

    render(){
        let ordlist = this.state.ordlist;
        //console.log('order render')

        return(<>
            <Growl ref={(el) => this.growl = el} style={{width:"70vw"}} sticky={"true"} position="top-right" />
            <div style={{backgroundColor:appTheme.pageBgColor,height:'100vh'}}>
                <div style={{display:'flex',flexDirection:'row',backgroundColor:appTheme.primaryColor,height:50,position:'fixed',top:0,left:0,right:0,color:appTheme.textColor,alignItems:'center'}}>
                    <i style={{fontSize:'1rem',marginLeft:12}} className="pi pi-arrow-left" onClick={()=>this.props.navProps.history.push('/')} ></i>
                    <div style={{fontSize:'1.2rem',marginBottom:3,marginLeft:'2rem'}}>My Orders</div>
                </div>
                <div style={{height:50}}></div>
                <div>
                   {ordlist && ordlist.length > 0 && <div>
                    
                    {ordlist.map((it)=>{
                        //console.log(it);
                        return(
                            <div key={it.ordid} className="navcard" style={{margin:"10px 12px",borderRadius:16,backgroundColor:'#ffffff',padding:12,boxShadow:'0 1px 3px rgb(0 0 0 / 30%)'}}>
                                <div style={{display:'flex',flexDirection:'row',borderBottom:'0.5px solid',padding:4,borderColor:appTheme.lightTextColor}}>
                                    <div style={{alignSelf:'center'}}>
                                        <img src={pizzicon} style={{width:40,height:40}}/>
                                    </div>
                                    <div style={{marginLeft:8}}>
                                        <div style={{fontSize:'1rem'}}>ORD ID: {it.ordcd}</div>
                                        <div style={{fontSize:'0.8rem',fontWeight:400,color:appTheme.lightTextColor}}>Ordered On: {it.orddt}</div>
                                    </div>
                                    <div style={{flex:1}}></div>
                                    
                                    <div style={{color:appTheme.primaryColor,fontSize:'0.6rem',fontWeight:600}}>{it.ordstatus}</div>
                                </div>
                                <div style={{marginTop:5}}>
                                    <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Sub Total: &#8377;{it.ordamt}</div>
                                    <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Tax: &#8377;{it.ordtax}</div>
                                    {parseInt(it.promodscnt) > 0 && <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Promo Discount: &#8377;{it.promodscnt}</div>}
                                    <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Delivery Price: &#8377;{it.delprice}</div>
                                    <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Total Amount: &#8377;{(parseFloat(it.ordtot) + parseFloat(it.delprice)).toFixed(2)}</div>
                                    <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Delivery Address: {it.deladdr}</div>
                                    <hr/>
                                </div>
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                    {it.ordstatus =='SUBMITTED' ? <div style={{color:appTheme.btnbgcolor,fontSize:'0.9rem'}} onClick={()=>this.setState({cancelPopup:true,selectedOrder:it})}><u>Cancel Order</u></div> : <div></div>}
                                    <div style={{color:appTheme.btnbgcolor,fontSize:'0.9rem'}} onClick={()=>this.orderItems(it)}>More Info</div>
                                </div>
                                
                            </div>
                        )
                    })}
                    
                    
                    
                    </div>} 
                
                
                
                </div>
                
                
            </div>
            <Sidebar  className="cart-screen" style={{width:'100%',height:'60%',padding:'0px !important',margin:'0px !important'}} visible={this.state.summaryDialog} showCloseIcon={false} onHide={()=>this.setState({summaryDialog:false})} fullScreen>
                <div style={{display:'flex',flexDirection:'row',backgroundColor:appTheme.primaryColor,height:50,position:'fixed',top:0,left:0,right:0,color:appTheme.textColor,alignItems:'center'}}>
                    <i style={{fontSize:'1rem',marginLeft:12}} className="pi pi-arrow-left" onClick={()=>this.setState({summaryDialog:false})} ></i>
                    <div style={{fontSize:'1.2rem',marginBottom:3,marginLeft:'2rem'}}>Order Summary</div>
                </div>
                <div key={this.state.selectedOrder.ordid} className="navcard" style={{margin:"10px 12px",borderRadius:16,backgroundColor:'#ffffff',padding:12,boxShadow:'0 1px 3px rgb(0 0 0 / 30%)',marginTop:'2rem'}}>
                    <div style={{display:'flex',flexDirection:'row',borderBottom:'0.5px solid',padding:4,borderColor:appTheme.lightTextColor}}>
                        <div style={{alignSelf:'center'}}>
                            <img src={pizzicon} style={{width:40,height:40}}/>
                        </div>
                        <div style={{marginLeft:8}}>
                            <div style={{fontSize:'1rem'}}>ORD ID: {this.state.selectedOrder.ordcd}</div>
                            <div style={{fontSize:'0.8rem',fontWeight:400,color:appTheme.lightTextColor}}>Ordered On: {this.state.selectedOrder.orddt}</div>
                        </div>
                        <div style={{flex:1}}></div>
                        
                        <div style={{color:appTheme.primaryColor,fontSize:'0.6rem',fontWeight:600}}>{this.state.selectedOrder.ordstatus}</div>
                    </div>
                    <div style={{marginTop:5}}>
                        <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Sub Total: &#8377;{this.state.selectedOrder.ordamt}</div>
                        <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Tax: &#8377;{this.state.selectedOrder.ordtax}</div>
                        {parseInt(this.state.selectedOrder.promodscnt) > 0 && <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Promo Discount: &#8377;{this.state.selectedOrder.promodscnt}</div>}
                        <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Delivery Price: &#8377;{this.state.selectedOrder.delprice}</div>
                        <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Total Amount: &#8377;{(parseFloat(this.state.selectedOrder.ordtot) + parseFloat(this.state.selectedOrder.delprice)).toFixed(2)}</div>
                        <div style={{fontSize:'0.8rem',color:appTheme.lightTextColor}}>Delivery Address: {this.state.selectedOrder.deladdr}</div>
                        <hr/>
                    </div>             
                </div>
                {this.state.orditems.map((it)=>{
                    return(
                    <div key={it.orditid} style={{display:'flex',flexDirection:'row',marginLeft:'1rem',marginTop:8}}>
                    <img src={it.prodimage} style={{width:50,height:50,alignSelf:'center'}}/>
                    <div style={{marginLeft:5}}>
                        <div>{it.name}</div>
                        <div style={{color:appTheme.lightTextColor,fontSize:'0.8rem'}}>{it.orditquan} x {it.size}</div>
                        <div style={{color:appTheme.lightTextColor,fontSize:'0.8rem'}}>Tax: &#8377;{it.ordittax}</div>
                        <div style={{color:appTheme.lightTextColor,fontSize:'0.8rem'}}>Total Amount: &#8377;{it.orditamt}</div>
                    
                    </div>
                    
                    </div>)
                })}


            </Sidebar>
            <Dialog showHeader={false} footer={this.cancelFooter()} style={{width:'70vw'}} visible={this.state.cancelPopup} onHide={()=>this.setState({cancelPopup:false})}>
            <div style={{marginTop:'1rem'}}>Do you want to Cancel Order? Provide Reason..</div>
            <span className="p-float-label mt-5">
            <InputText id="reason" value={this.state.cancelReason} onChange={(e)=>this.setState({cancelReason:e.target.value})} />
            <label htmlFor="reason">Cancellation Reasson</label>
            </span>
            
            
            </Dialog>
            </>
            
        )
    }
}


export default Orders;