import React from "react";
import Home from "./Home";
import Cart from "./Cart";
import Global from "../utils/Global";
import  Orders  from "./Orders";
import  Contact  from "./Contact";
import  About  from "./About";
import Navbar from "../components/Navbar";
export default class Launch extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            currScr : '/',
            scrArr:['/','/home','/cart','/about','/orders','/contact']
        }
    }

    componentDidMount(props){
        console.log(props)
        this.handleNav();
        console.log('launch')
    }
    componentDidUpdate(prevProps){
        if(this.props.location.pathname != prevProps.location.pathname){
            this.handleNav();
        }
        
    }

    handleNav=()=>{
        let scrExist = false;
        for(let i=0;i<this.state.scrArr.length;i++){
            if(this.props.location.pathname == this.state.scrArr[i]) scrExist=true;
        }

        if(scrExist){
            this.setState({currScr:this.props.location.pathname})
        }else{
            this.props.history.replace('/')
            this.setState({currScr:'/'})
        }
    }



    render(){
        return(
            <>
            {(this.state.currScr == '/' || this.state.currScr == '/home') && <Home navProps={this.props}/>}
            {this.state.currScr=='/cart' && <Cart navProps={this.props}/>} 
            {this.state.currScr=='/about' && <About navProps={this.props}/>}            
            {this.state.currScr=='/contact' && <Contact navProps={this.props}/>}            
            {this.state.currScr=='/orders' && <Orders navProps={this.props}/>}            
           
            </>
            )
    }
}