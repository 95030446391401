
class userProfile {

    static userobj = {};
    static custobj = {};
    static navobj = {}; 
    
    static removeCustObj(){
        this.custobj = {};
        window.localStorage.removeItem('svrcustdata');
    }


    static setCustObj(inpobj){
        this.custobj = inpobj;
        window.localStorage.setItem('svrcustdata', JSON.stringify(inpobj));
    }

    static getCustObj(){
        if(this.custobj && this.custobj.orgid){
            return this.custobj;
        }else {
            let custobj = window.localStorage.getItem('svrcustdata');
            if(custobj){
                custobj = JSON.parse(custobj)
            }
            this.custobj = custobj || {};
            return custobj;
        } 
    }



}

export default userProfile;